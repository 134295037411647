import { Heading } from "@ui/heading"
import { Headline } from "@ui/headline"
import { Stack } from "@ui/stack"
import { Row } from "@ui/row"
import { Button } from "@ui/button"
import { Section } from "./section"
import Link from "next/link"
import { Divider } from "@ui/divider"
import VisuallyHidden from "@reach/visually-hidden"

export const Pricing = () => {
  return (
    <Section gap={[7, 8]}>
      <Stack gap={[6, 7]} alignItems="center">
        <Stack gap="2" alignItems="center">
          <Heading as="h2" level="3" variant="subheading">
            Pricing
          </Heading>
          <Heading as="div" level={[1, "m"]} variant="marketing">
            Let's talk money
          </Heading>
        </Stack>
        <Row as="ul" style={{ listStyle: "initial" }}>
          <Row.Column>
            <li>Paying your caregiver via direct deposit</li>
            <li>Calculation of pay, taxes, CPP, {"&"} EI amounts</li>
            <li>Producing and filing T4s at tax time</li>
            <li>Record of Employment (ROE) forms</li>
          </Row.Column>
          <Row.Column>
            {/* eslint-disable-next-line sentence-case/sentence-case */}
            <li>Canada Revenue Agency (CRA) registration</li>

            <li>Remittance of all source deductions</li>

            <li>Producing pay stubs {"&"} receipts each pay period</li>
          </Row.Column>
        </Row>

        <Link href="https://www.heartpayroll.com/pricing" passHref>
          <Button size="cta" variant="cta">
            Only $50 / month
            <VisuallyHidden>. View pricing.</VisuallyHidden>
          </Button>
        </Link>
      </Stack>

      <Divider>
        <Heading as="h3" style={{ textAlign: "center" }} level="3">
          Additional fees
        </Heading>
      </Divider>

      <Stack gap={[4, 5]}>
        <Row justifyContent="space-evenly">
          <Row.Column flexBasis="40%" style={{ textAlign: "center" }}>
            <Heading as="div" level={[1, "m"]} variant="marketing">
              $0 setup
            </Heading>
            <Headline>Get Started with no setup fee.</Headline>
          </Row.Column>

          <Row.Column flexBasis="40%" style={{ textAlign: "center" }}>
            <Heading as="div" level={[1, "m"]} variant="marketing">
              $10 monthly
            </Heading>
            <Headline>Optional registration {"&"} remittance of WCB/WSIB premiums.</Headline>
          </Row.Column>
        </Row>
      </Stack>
    </Section>
  )
}
