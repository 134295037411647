import Button from "@ui/button"
import { Stack } from "@ui/stack"
import Link from "next/link"
import { Section } from "@marketing/section"

export const GetStartedForFree = () => {
  return (
    <Section bg="primary.100">
      <Stack alignItems="center">
        <Link href={"#signup-card"} passHref>
          <Button variant="secondary" size="cta">
            Get started for free
          </Button>
        </Link>
      </Stack>
    </Section>
  )
}
