import { Heading } from "@ui/heading"
import { Stack } from "@ui/stack"
import { Row } from "@ui/row"
import Image from "next/image"
import { Section } from "./section"
import image from "../../public/images/heartpayroll-dad-and-son-crop-89bf14741b.jpeg"

type WhatWeOfferProps = {
  keyword: string
  aKeyword: string
}

export const WhatWeOffer = ({ keyword, aKeyword }: WhatWeOfferProps) => {
  return (
    <Section bg="gray.10">
      <Row>
        <Row.Column flexBasis="70%">
          <Stack gap="2">
            <Heading as="h2" level="3" variant="subheading">
              What we offer
            </Heading>
            <Heading as="div" level={[1, "m"]} variant="marketing">
              We're here to make payroll less confusing
            </Heading>
          </Stack>
        </Row.Column>
        <Row.Column flexBasis="30%">
          <Stack as="aside" gap="2" pt="3" paddingX={[0, 2]} borderTop="1px solid" borderTopColor="gray.border">
            <Heading as="h3" level="3" variant="subheading">
              Questions?
            </Heading>
            <p>
              <a href="https://heartpayroll.com/contact">Get in touch</a> with our dedicated team.
            </p>
          </Stack>
        </Row.Column>
      </Row>
      <Row>
        <Row.Column flexBasis="30%" flexGrow="0" display={["none", "block"]}>
          <Image alt="" {...image} layout="responsive" />
        </Row.Column>
        <Row.Column flexBasis="30%" flexGrow="0" display={["block", "none"]}>
          <Image alt="" {...image} layout="responsive" width="16" height="9" objectFit="cover" />
        </Row.Column>
        <Row.Column flexBasis="70%">
          <Row>
            <Row.Column>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  Direct deposit
                </Heading>
                <p>
                  Forget writing cheques. Pay your {keyword} effortlessly with direct deposit. Both you and your{" "}
                  {keyword} will enjoy how easy payment becomes.{" "}
                </p>
              </Stack>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  Remittances
                </Heading>
                <p>
                  Remittances of all source deductions are submitted monthly as required on your behalf, on time and
                  accurately.
                </p>
              </Stack>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  Paystubs
                </Heading>
                <p>
                  Like to keep everything in your records? Both you and your {keyword} receive pay stubs and receipts
                  each pay period.
                </p>
              </Stack>
            </Row.Column>
            <Row.Column>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  CRA Registration
                </Heading>
                <p>
                  Now that you’re an employer, we ensure you’re registered as such with the Canada Revenue Agency (CRA).
                </p>
              </Stack>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  T4s & T4 summaries
                </Heading>
                <p>
                  We mean it when we say we handle all things payroll. We even take care of producing and filing T4s at
                  tax time.
                </p>
              </Stack>
              <Stack gap="3">
                <Heading as="h3" level="3" variant="marketing">
                  ROE
                </Heading>
                <p>
                  We’re with you right to the end. When you no longer require {aKeyword}, we’ll complete and provide a
                  Record of Employment (ROE).
                </p>
              </Stack>
            </Row.Column>
          </Row>
          <Stack gap="3">
            <Heading as="h3" level="3" variant="marketing">
              All calculations
            </Heading>
            <p>
              Sleep easy knowing that the calculation of Net and Gross pay, taxes, CPP, and EI amounts are paid
              correctly and on time.
            </p>
          </Stack>
        </Row.Column>
      </Row>
    </Section>
  )
}
