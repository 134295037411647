import css from "@styled-system/css"
import styled from "styled-components"
import { Stack } from "@ui/stack"

export type SectionProps = {
  childMaxWidth?: string
}

export const Section = styled(Stack).attrs({
  as: "section",
  alignItems: "center",
})<SectionProps>`
  ${css({
    paddingX: [4, 6],
    paddingY: [6, 8],
    "> * ": {
      width: "100%",
      maxWidth: "page.marketing",
    },
  })}
  ${(props) =>
    props.childMaxWidth &&
    css({
      "> * ": {
        maxWidth: props.childMaxWidth,
      },
    })}
`

Section.displayName = "Section"
Section.defaultProps = {
  gap: "6",
}
