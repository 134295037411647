import css from "@styled-system/css"
import Image from "next/image"
import styled from "styled-components"
import { Section } from "@marketing/section"

import { Button } from "@ui/button"
import { Heading } from "@ui/heading"
import { Row } from "@ui/row"
import Stack from "@ui/stack"

import image from "../../public/images/HP-hero.jpeg"
import { ReactNode } from "react"
import Box from "@ui/box"

type Hero = {
  heading: ReactNode
  keyword: ReactNode
}

export const Hero = ({ heading, keyword }: Hero) => {
  return (
    <Section style={{ padding: 0 }}>
      <Row as={ForceHiddenImg} gap={["0px", 3]} bg="primary.300" paddingX="0px" direction="horizontal">
        <Image priority alt="" objectFit="cover" {...image} style={{ flexShrink: 3 }} />

        <Row.Column paddingY={5} paddingX={[4]} justifyContent="center" alignItems="flex-start" flexShrink={1}>
          <Stack gap="2">
            <HeadingContainer>
              <Heading as="h1" level={["3", "2"]} variant="subheading" color="white">
                {heading}
              </Heading>
            </HeadingContainer>

            <Stack gap="1">
              <Box width={["100%", "80%"]}>
                <Heading as="div" level={["1", "m"]} variant="marketing" color="white">
                  Pay your {keyword} on time, every time.
                </Heading>
              </Box>

              <Box width={["100%", "60%"]}>
                <Heading as="div" level={[5, 2]} variant="subheading" color="white">
                  Our expert payroll team will take care of your {keyword}, while you take care of your familly.
                </Heading>
              </Box>
            </Stack>
          </Stack>

          <Button href="#signup-card" size={["medium", "large"]} fluid={[true, false]}>
            Sign up today
          </Button>
        </Row.Column>
      </Row>
    </Section>
  )
}

const HeadingContainer = styled.div`
  ${css({
    width: "fit-content",
    borderTop: "3px solid white",
  })}
`

const ForceHiddenImg = styled.div`
  ${css({
    img: {
      display: ["none !important", "block !important"],
    },
  })}
`
