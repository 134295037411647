import { SignupCard } from "@cards/signup-form"
import { Heading } from "@ui/heading"
import { Headline } from "@ui/headline"
import { Row } from "@ui/row"
import { Section } from "@marketing/section"
import { Stack } from "@ui/stack"

type SignupSection = {
  keywords: string
}

export const SignupSection = ({ keywords }: SignupSection) => {
  return (
    <Section childMaxWidth="page.max">
      <Row>
        <Row.Column>
          <Stack gap="3">
            <Heading as="h2" level={"3"} variant="subheading">
              Signup today
            </Heading>
            <Heading as="div" level={[2, "m"]} variant="marketing">
              What are you waiting for?
            </Heading>
            <Headline>Join thousands of people who use HeartPayroll every day to pay their {keywords}.</Headline>
          </Stack>
        </Row.Column>
        <Row.Column variant="secondary">
          <Stack width={["100%", "page.half"]}>
            <SignupCard />
          </Stack>
        </Row.Column>
      </Row>
    </Section>
  )
}
