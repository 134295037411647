/* eslint-disable sentence-case/sentence-case */
import css from "@styled-system/css"
import { Heading } from "@ui/heading"
import { Card } from "@ui/card"
import fiverStar from "../../public/images/five-star.svg"
import googleReview from "../../public/images/google-reviews.svg"
import Image from "next/image"
import { Stack } from "@ui/stack"
import Link from "next/link"
import styled from "styled-components"

export const Testimonials = () => (
  <Stack as="section" gap="6" bg="gray.50" paddingY={[6, 8]} alignItems="center">
    <Stack
      gap="4"
      justifyContent="space-between"
      alignItems="flex-start"
      direction={"vertical"}
      paddingX={[4, 6]}
      width="100%"
      maxWidth="page.marketing"
    >
      <Heading as="h2" level="3" variant="subheading">
        Testimonials
      </Heading>
      <Heading as="div" level={[1, "m"]} variant="marketing">
        The verdict is in!
      </Heading>
    </Stack>

    <List>
      {TESTIMONIALS.map((testimonial) => (
        <TestimonialCard key={testimonial.author} {...testimonial} />
      ))}
    </List>

    <Link
      href="https://www.google.com/search?q=heartpayroll&oq=heartpayroll&aqs=chrome..69i57j46i175i199i512j69i60l6.4119j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b35fae71195d5:0xb4be3842a48c427,1,,,"
      passHref
    >
      <a style={{ alignSelf: "center" }}>
        <Image
          alt="HeartPayroll has 5 stars on Google Reviews"
          {...googleReview}
          width={googleReview.width}
          height={googleReview.height}
          layout="fixed"
        />
      </a>
    </Link>
  </Stack>
)

const List = styled.div`
  ${css({
    width: "100%",
    overflowX: "scroll",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: 4,
    paddingX: [4, 6],
  })}
`
const TESTIMONIALS = [
  {
    url: "https://g.co/kgs/Uec4MJ",
    author: "Brian Stubbs",
    text: "HeartPayroll has been the perfect stress free solution for providing legal payroll services to our in home child care worker.  They are always professional and prompt in addressing our concerns and needs.  I highly recommend HeartPayroll if you need to hire anyone in your home for services.",
  },
  {
    url: "https://g.co/kgs/hkZjpx",
    author: "Kara Burns",
    text: "We’ve been using Heart Payroll for a couple of years. It’s very easy to set up and user friendly. They’re extremely prompt in their response time and take care of everything related to employing a nanny. Highly recommend.",
  },
  {
    url: "https://g.co/kgs/T8thxQ",
    author: "Mel Mugridge",
    text: "We had such a great experience with HeartPayroll they were so informative, very quick to reply/help out if you need assistance. Very easy to work with and would recommend them to anyone looking for payroll services.",
  },
  {
    url: "https://g.co/kgs/ejtm3D",
    author: "Derrick Newman",
    text: "Using Heartpayroll to help with paying our nanny has been incredible. They take care of absolutely everything and put your mind at ease. No need to waste your time on the CRA website as they do it all for you and for a very nominal fee. I would highly recommend Heartpayroll to anyone in the same position.",
  },
  {
    url: "https://g.co/kgs/2VPHwL",
    author: "David Tetzlaff",
    text: "Heart payroll made the whole hiring and paying and termination with an employee so easy and stress free! They were incredibly professional, knowledgeable, prompt and friendly. I felt confident they were handing the taxes appropriately and it took the stress off of my shoulders. Highly recommend!",
  },
  {
    url: "https://g.co/kgs/HYtwxC",
    author: "Crystal Luxmore",
    text: "A very professional, flexible service that helped me pay my nanny and meet all Government and labour rules without knowing anything about them myself, highly recommend",
  },
  {
    url: "https://g.co/kgs/XKazUn",
    author: "Julia Campbell",
    text: "Great service to work with! They've helped us with navigating Quebec's payroll systems and making sure we do things legally throughout. They've also always been responsive when I've reached out with needs or questions.",
  },
  {
    url: "https://g.co/kgs/Xep6cC",
    author: "Caroline Eberdt",
    text: "We were very happy with this payroll service. They were very responsive and always on the ball whenever we changed hours (or when my nanny had to change bank accounts) - and so nice to have all of the T4 and ROE taken care of as well. We are all finished with our nanny now but would definitely recommend Heart Payroll!",
  },
  {
    url: "https://g.co/kgs/fazqpf",
    author: "Jackie Ficzere",
    text: "Could not have asked for a better service to support me and my nanny while she was employed. Everything from start to finish has been handled by friendly, supportive staff and in a timely manner. I highly recommend using this service!",
  },
]

type TestimonialCardProps = {
  url?: string
  text: string
  author: string
}

const TestimonialCard = ({ url, text, author }: TestimonialCardProps) => (
  <Card
    flexShrink={0}
    width="400px"
    maxWidth="80%"
    heading={
      <Stack gap="2" alignItems="flex-start" direction="vertical">
        <span>{author}</span>
        <Image
          alt="5 stars on Google Reviews"
          {...fiverStar}
          width={fiverStar.width / 8}
          height={fiverStar.height / 8}
          layout="fixed"
        />
      </Stack>
    }
  >
    <Stack height="100%" gap={[0, 2]} justifyContent="space-between">
      <p>{text}</p>
      {url && (
        <Link href={url} passHref>
          <a>View on Google</a>
        </Link>
      )}
    </Stack>
  </Card>
)
